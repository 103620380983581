import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 25 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M17.66 21.535h-10c-4 0-5-1-5-5v-8c0-4 1-5 5-5h10c4 0 5 1 5 5v8c0 4-1 5-5 5m-3-13h5m-4 4h4m-2 4h2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M9.16 11.827a1.81 1.81 0 1 0 0-3.62 1.81 1.81 0 0 0 0 3.62m3.5 5.04a3.02 3.02 0 0 0-2.74-2.72 7.7 7.7 0 0 0-1.52 0 3.03 3.03 0 0 0-2.74 2.72"
    }, null, -1)
  ])))
}
export default { render: render }